import React, { useState, useEffect } from 'react';
import './shop-details.css';
import { useParams, Link } from 'react-router-dom';
import nextIcon from './assets/next.png';
import backIcon from './assets/back.png';
import panier from './assets/panier.png';
import ProgressComponent from './components/ProgressComponent';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css'; // Import notification CSS
const ShopDetails = () => {
    const { id } = useParams();
    const decodedId = atob(id);

    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedGranite, setSelectedGranite] = useState(null);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`http://localhost:4000/produits/details/${decodedId}`);
                const data = await response.json();
                setProduct(data);
                setSelectedGranite(data.granit[0]);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching product details:', error);
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [decodedId]);

    const nextImage = () => {
        if (currentIndex < product.granit.length - 3) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevImage = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleGraniteClick = (granite) => {
        setSelectedGranite(granite);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!product) {
        return <p>Product not found.</p>;
    }

    const handleQuantityChange = (event) => {
        setQuantity(Math.max(1, parseInt(event.target.value, 10) || 1));
    };
    const saveSelectedProductToStorage = () => {
      try {
          // Check if the selected granite data is valid
          if (!selectedGranite || !selectedGranite.color || !selectedGranite.price) {
              throw new Error('Produit data is incomplete');
          }
  
          // Retrieve existing cart data from localStorage, if any
          let cartData = JSON.parse(localStorage.getItem('cart')) || {};
  
          // Check if the product already exists in the cart
          if (cartData['produit']) {
              // If it exists, update the quantity
              cartData['produit'].quantity += quantity; // Update quantity, you can customize this as needed
          } else {
              // If it doesn't exist, add the selected granite data to the cart
              cartData['produit'] = {
                  name: selectedGranite.name,
                  color: selectedGranite.color,
                  price: selectedGranite.price,
                  image: selectedGranite.image,
                  quantity: quantity
              };
          }
  
          // Save the updated cart object to localStorage
          localStorage.setItem('cart', JSON.stringify(cartData));
  
          // Success notification
          NotificationManager.success('Produit added to cart successfully!', 'Success');
      } catch (error) {
          // Failure notification
          NotificationManager.error(error.message, 'Failed to Add Produit');
      }
  };
  
  
    // Calculate totals
    const totalProductPrice = quantity; // Total quantity
    const totalOptionsPrice = selectedGranite.price; // Granite price
    const total = totalProductPrice * totalOptionsPrice; // Final total price

    return (
        <div className="container-all">
            <ProgressComponent step={1} />
            <div className="container">
                <div className="product-details">
                    <div className="left-column">
                        <h2 className="product-name">{product.name}</h2>
                        <div className="product-image">
                            <img src={selectedGranite.image} alt={selectedGranite.color} />
                        </div>
                        <div className="image-carousel">
                            <button className="back-button" onClick={prevImage} disabled={currentIndex === 0}>
                                <img src={backIcon} alt="Back" />
                            </button>
                            <div className="carousel-images">
                                {product.granit.slice(currentIndex, currentIndex + 3).map((item, index) => (
                                    <img
                                        key={item._id}
                                        src={item.image}
                                        alt={`Carousel ${index}`}
                                        className="carousel-image"
                                        onClick={() => handleGraniteClick(item)}
                                    />
                                ))}
                            </div>
                            <button className="next-button" onClick={nextImage} disabled={currentIndex >= product.granit.length - 3}>
                                <img src={nextIcon} alt="Next" />
                            </button>
                        </div>
                    </div>
                    <div className="right-column">
                        <p style={{ color: "#0074A6", fontSize: "18px" }}>Etape 1</p>
                        <div style={{ border: '1px solid gray', padding: 20 }}>
                            <h1>{product.name}</h1>
                            <h2>{selectedGranite.color} - {selectedGranite.price} €</h2>
                            <p style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
                                {product.description}
                            </p>
                            <div className="price-details">
                                <div className="price-item">
                                    <span className="price-item-label">Total du produit</span>
                                    <span className="price-item-value">{quantity}</span>
                                </div>
                                <div className="price-item">
                                    <span className="price-item-label">Total des options</span>
                                    <span className="price-item-value">{totalOptionsPrice.toFixed(2)} €</span>
                                </div>
                                <div className="price-item">
                                    <span className="price-item-label">Total</span>
                                    <span className="price-item-value">{total.toFixed(2)} €</span>
                                </div>
                                <div className="quantity-selector">
                                    <button
                                        className="quantity-button"
                                        onClick={() => setQuantity(Math.max(1, quantity - 1))}
                                        disabled={quantity === 1}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="number"
                                        className="quantity-input"
                                        value={quantity}
                                        onChange={handleQuantityChange}
                                        min="1"
                                    />
                                    <button
                                        className="quantity-button"
                                        onClick={() => setQuantity(quantity + 1)}
                                        disabled={quantity === 10}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            <div className="buttons">
    <button className="add-to-cart" onClick={saveSelectedProductToStorage}>
        <img src={panier} alt="Basket Icon" className="icon" />
        Ajouter au panier
    </button>
    <Link to={`/shop-details-next/${id}`} onClick={saveSelectedProductToStorage}  className="next">
        Suivant
    </Link>
</div>
<NotificationContainer />

                        </div>
                    </div>
                </div>
            </div>
            <div className="product-full-description">
                <h3>Caractéristiques du produit :</h3>
                <p>
                    {product.description.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </p>
            </div>
        </div>
    );
};

export default ShopDetails;
