import React, { useState, useEffect } from 'react';
import './Shop.css'; // Import the CSS file for styles
import { Link } from 'react-router-dom';
import DropwDownIcon from '../src/assets/dropdown-category.png';

const Shop = () => {
  const [selectedCategory, setSelectedCategory] = useState('Category 1');
  const [visibleSubcategories, setVisibleSubcategories] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]); // State to hold products

  useEffect(() => {
    // Fetch products from the API
    fetch('http://localhost:4000/produits/all')
      .then(response => response.json())
      .then(data => {
        setProducts(data);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const categories = [
    { name: 'Category 1', subcategories: ['Subcategory 1', 'Subcategory 2'] },
    { name: 'Category 2', subcategories: ['Subcategory 3', 'Subcategory 4'] },
  ];

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
    setVisibleSubcategories(prev => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to encode product ID
  const encodeId = (id) => {
    return btoa(id); // Base64 encoding
  };

  return (
    <div className="shop-container">
      <div className="categories">
        <h3>Categories</h3>
        <ul>
          {categories.map((category, index) => (
            <li key={index}>
              <div onClick={() => handleCategoryClick(category.name)} className="category-name">
                <span>{category.name}</span>
                <span>
                  <img
                    src={DropwDownIcon}
                    alt="dropdown icon"
                    style={{
                      width: '20px',
                      height: '20px',
                      transform: visibleSubcategories[category.name] ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease'
                    }}
                  />
                </span>
              </div>
              {visibleSubcategories[category.name] && (
                <ul className="subcategory-list">
                  {category.subcategories.map((subcategory, subIndex) => (
                    <li key={subIndex}>{subcategory}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className="products">
        <input
          type="text"
          placeholder="Chercher un produit ..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-bar"
        />
        <h3>{selectedCategory}</h3>
        <div className="products-container">
          {filteredProducts.map((product, index) => (
            <Link to={`/shop-details/${encodeId(product._id)}`} key={index} className="product-card">
              <div className="image-container">
                <img src={product.image} alt={product.name} />
                <div className="overlay">
                  <i className="icon fas fa-shopping-cart"></i>
                  <i className="icon fas fa-eye"></i>
                </div>
              </div>
              <h4>{product.name}</h4>
              <p>{product.price} €</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shop;
