import React, { useState, useEffect } from 'react';
import './Panier.css';
import trashIcon from './assets/trash.png';

const DeleteConfirmationDialog = ({ onConfirm, onCancel }) => {
    return (
        <div className="dialog-overlay">
            <div className="dialog-content">
                <p>Voulez-vous vraiment supprimer ce produit ?</p>
                <div className="dialog-buttons">
                    <button onClick={onCancel} className="cancel-button">Annuler</button>
                    <button onClick={onConfirm} className="confirm-button">Oui</button>
                </div>
            </div>
        </div>
    );
};

const Panier = () => {
    const [cartData, setCartData] = useState(null);
    const [productToDelete, setProductToDelete] = useState(null);
    const [deleteType, setDeleteType] = useState(null); // To track which type to delete

    useEffect(() => {
        const storedCartData = JSON.parse(localStorage.getItem('cart'));
        if (storedCartData) {
            setCartData(storedCartData);
        }
    }, []);

    const updateLocalStorage = (data) => {
        localStorage.setItem('cart', JSON.stringify(data));
    };

    const handleRemove = (type) => {
        setCartData(prevData => {
            const updatedData = { ...prevData };
            if (type === 'produit') {
                delete updatedData.produit;
            } else if (type === 'Motifs') {
                delete updatedData.Motifs;
            }
            updateLocalStorage(updatedData);
            return updatedData;
        });
        setProductToDelete(null);
    };

    const handleQuantityChange = (type, index, delta) => {
        setCartData(prevData => {
            const updatedData = { ...prevData };
            if (type === 'produit') {
                const updatedItem = { ...updatedData.produit };
                updatedItem.quantity = Math.max(1, (updatedItem.quantity || 1) + delta);
                updatedData.produit = updatedItem;
            } else if (type === 'Motifs') {
                const updatedItem = { ...updatedData.Motifs[index] };
                updatedItem.quantity = Math.max(1, (updatedItem.quantity || 1) + delta);
                updatedData.Motifs[index] = updatedItem;
            }
            updateLocalStorage(updatedData);
            return updatedData;
        });
    };

    const totalAmount = (cartData ? (
        (cartData.produit?.price || 0) * (cartData.produit?.quantity || 1) + 
        (cartData.Motifs || []).reduce((total, motif) => total + (motif.price * (motif.quantity || 1)), 0)
    ) : 0).toFixed(2);

    return (
        <div className="panier-container">
            <div className="basket-items-panier">
                {cartData && (cartData.produit || (cartData.Motifs && cartData.Motifs.length > 0)) ? (
                    <>
                        {cartData.produit && (
                            <div className="product-card-panier">
                                <img src={cartData.produit.image} alt="Produit" className="product-image-panier" />
                                <div className="product-details-panier">
                                    <h2 className="product-name-panier">{cartData.produit.color}</h2>
                                    <p className="product-price-panier">Price: {cartData.produit.price} €</p>
                                    <div className="product-quantity-panier">
                                        <button onClick={() => handleQuantityChange('produit', null, -1)}>-</button>
                                        <span>{cartData.produit.quantity || 1}</span>
                                        <button onClick={() => handleQuantityChange('produit', null, 1)}>+</button>
                                    </div>
                                </div>
                                <button 
                                    className="delete-button-panier" 
                                    onClick={() => { setProductToDelete('produit'); setDeleteType('produit'); }}
                                >
                                    <img src={trashIcon} alt="Delete" />
                                </button>
                            </div>
                        )}
                        {cartData.Motifs && cartData.Motifs.map((motif, index) => (
                            <div className="product-card-panier" key={index}>
                                <img src={motif.image} alt="Motif" className="product-image-panier" />
                                <div className="product-details-panier">
                                    <h2 className="product-name-panier">{motif.description}</h2>
                                    <p className="product-price-panier">Price: {motif.price} €</p>
                                    <div className="product-quantity-panier">
                                        <button onClick={() => handleQuantityChange('Motifs', index, -1)}>-</button>
                                        <span>{motif.quantity || 1}</span>
                                        <button onClick={() => handleQuantityChange('Motifs', index, 1)}>+</button>
                                    </div>
                                </div>
                                <button 
                                    className="delete-button-panier" 
                                    onClick={() => { setProductToDelete('Motifs'); setDeleteType('Motifs'); }}
                                >
                                    <img src={trashIcon} alt="Delete" />
                                </button>
                            </div>
                        ))}
                    </>
                ) : (
                    <p className="empty-basket-panier">Votre panier est vide</p>
                )}
            </div>

            <div className="payment-section-panier">
                <h3>Total À payer : {totalAmount} €</h3>
                <button className="checkout-button-panier">Payer</button>
                <div className="payment-methods-panier">
                    <h4>Méthodes de paiement acceptées :</h4>
                    <img src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/visa.svg" alt="Visa" className="payment-icon-panier" />
                    <img src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/amex.svg" alt="American Express" className="payment-icon-panier" />
                    <img src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/mastercard.svg" alt="Mastercard" className="payment-icon-panier" />
                    <img src="https://woocommerce.com/wp-content/plugins/woocommerce/includes/gateways/paypal/assets/images/paypal.png" alt="PayPal" className="payment-icon-panier" />
                </div>
            </div>

            {productToDelete !== null && (
                <DeleteConfirmationDialog 
                    onConfirm={() => handleRemove(deleteType)} 
                    onCancel={() => setProductToDelete(null)} 
                />
            )}
        </div>
    );
};

export default Panier;
