import React from 'react';
import './Footer.css'; // Import the CSS for the footer
import logo from '../logo.svg'

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-column">
          <h4>Obseques France</h4>
          <p>O123 Consectetur at ligula 10660</p>
          <p>010-020-0340</p>
          <p>info@company.com</p>
        </div>
        <div className="footer-column">
          <h4>Produits</h4>
          <p>O123 Consectetur at ligula 10660</p>
          <p>010-020-0340</p>
          <p>info@company.com</p>
        </div>
        <div className="footer-column">
          <h4>Informations</h4>
          <p>O123 Consectetur at ligula 10660</p>
          <p>010-020-0340</p>
          <p>info@company.com</p>
        </div>
      </div>
      <div className="footer-logo">
      <img src={logo} alt="Logo" style={{ height: '126px', width: '176px' }} />
      </div>
    </footer>
  );
};

export default Footer;
