import React from 'react';
import Slider from 'react-slick';
import './Carousel.css'; // Import the CSS for the carousel

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <i className="fas fa-chevron-left carousel-arrow carousel-arrow-left"></i>,
    nextArrow: <i className="fas fa-chevron-right carousel-arrow carousel-arrow-right"></i>,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div className="carousel-slide">
          <img src="https://monument-funeraire-france.com/wp-content/uploads/2015/09/design-sans-titre-3.webp" alt="Slide 1" />
          <div className="carousel-text">This is Slide 1</div>
        </div>
        <div className="carousel-slide">
          <img src="https://monument-funeraire-france.com/wp-content/uploads/2015/09/design-sans-titre-3.webp" alt="Slide 2" />
          <div className="carousel-text">This is Slide 2</div>
        </div>
        <div className="carousel-slide">
          <img src="https://monument-funeraire-france.com/wp-content/uploads/2015/09/design-sans-titre-3.webp" alt="Slide 3" />
          <div className="carousel-text">This is Slide 3</div>
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
