import React, { useState } from "react";
import "./DemandeDevis.css";
import coffinIcon from './assets/coffin_4582580 1.png';
import c from './assets/illumination_14124261 1.png';
import airplane from './assets/airplane_479822 1.png';
import devisIconImage from'./assets/devisPageIcon.png';
import FeedbackClients from './components/FeedbackClients';
import rafiki from './assets/rafiki.png'

const DemandeDevis = () => {
    const [formData, setFormData] = useState({
      contactFor: "",
      funeralType: "",
      civility: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      postalCode: "",
      offersConsent: "Non",
      partnersConsent: "Non",
    });
  
    // State to control the visibility of both forms
    const [isFirstFormVisible, setFirstFormVisible] = useState(true); // Initially visible
    const [isSecondFormVisible, setSecondFormVisible] = useState(false);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const toggleFirstForm = () => {
      setFirstFormVisible(!isFirstFormVisible); // Toggle first form
    };
  
    const toggleSecondForm = () => {
      setSecondFormVisible(!isSecondFormVisible); // Toggle second form
    };
  
    const handleNextClick = () => {
      setSecondFormVisible(true); // Show the second form when "Suivant" is clicked
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(formData); // Add your form submission logic here
    };
  
    return (
      <div>
         <div className="demande-devis">
        <h1 className="info-heading">Demandez un devis obsèques</h1>
        <p className="text-demande-devis">Réponse d'un conseiller dans les 30 min</p>
  
        {/* Section 1: Contact for */}
        <div className="form-section-container">
  {/* Left side: Form section */}
  <div className={`form-section ${isFirstFormVisible ? "open" : "collapsed"}`}>
    <div className="section-header" onClick={toggleFirstForm}>
      <h2>Votre demande de devis</h2>
      <span className={`arrow ${isFirstFormVisible ? "up" : "down"}`}></span>
      </div>

    {isFirstFormVisible && (
      <>
        <p className="text-demande-devis">Nous contacter pour *</p>
        <br/>
        <div className="funeral-types">
          <label>
            <input
              type="radio"
              name="contactFor"
              value="Un décès survenu"
              onChange={handleInputChange}
            />
            Un décès survenu
          </label>
          <label>
            <input
              type="radio"
              name="contactFor"
              value="Un proche en fin de vie"
              onChange={handleInputChange}
            />
            Un proche en fin de vie
          </label>
        </div>

        <p className="text-demande-devis">Types d’obséques *</p>
        <br/>
        <div className="funeral-types">
          <label>
            <input
              type="radio"
              name="funeralType"
              value="Inhumation"
              onChange={handleInputChange}
            />
            <img src={coffinIcon} alt="Inhumation" className="radio-img" />
            Inhumation
          </label>
          <label>
            <input
              type="radio"
              name="funeralType"
              value="Crémation"
              onChange={handleInputChange}
            />
            <img src={c} alt="Crémation" className="radio-img" />
            Crémation
          </label>
          <label>
            <input
              type="radio"
              name="funeralType"
              value="Rapatriement"
              onChange={handleInputChange}
            />
            <img src={airplane} alt="Rapatriement" className="radio-img" />
            Rapatriement
          </label>
        </div>
        <button className="next-button" onClick={handleNextClick}>
      Suivant
    </button>
      </>
    )}
  </div>

  {/* Right side: Image and button */}
  <div className="image-content">
    <img src={devisIconImage} alt="Devis Icon" className="devis-icon-img" />
  </div>
        </div>

  
        {/* Section 2: Contact Information */}
        <div className={`form-section ${isSecondFormVisible ? "open" : "collapsed"}`}>
          <div className="section-header" onClick={toggleSecondForm}>
            <h2>Nous vous contacterons</h2>
            <span className={`arrow ${isSecondFormVisible ? "up" : "down"}`}></span>
          </div>
  
          {isSecondFormVisible && (
            <>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="civility"
                    value="Monsieur"
                    onChange={handleInputChange}
                  />
                  Monsieur
                </label>
                <label>
                  <input
                    type="radio"
                    name="civility"
                    value="Madame"
                    onChange={handleInputChange}
                  />
                  Madame
                </label>
              </div>
  
              {/* Contact Form */}
              <div className="contact-form">
                <input
                  type="text"
                  name="firstName"
                  placeholder="Prénom"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Nom"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Téléphone"
                  onChange={handleInputChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="postalCode"
                  placeholder="Code postale-Ville"
                  onChange={handleInputChange}
                />
              </div>
  
              {/* Consents */}
              <div className="consent-group">
                <label className="text-demande-devis">
                  J’accepte de recevoir des offres de services et commerciales
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="offersConsent"
                        value="Oui"
                        onChange={handleInputChange}
                      />
                      Oui
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="offersConsent"
                        value="Non"
                        onChange={handleInputChange}
                        defaultChecked
                      />
                      Non
                    </label>
                  </div>
                </label>
                <label className="text-demande-devis">
                  J’accepte de recevoir des informations commerciales des partenaires OGF
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="partnersConsent"
                        value="Oui"
                        onChange={handleInputChange}
                      />
                      Oui
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="partnersConsent"
                        value="Non"
                        onChange={handleInputChange}
                        defaultChecked
                      />
                      Non
                    </label>
                  </div>
                </label>
              </div>
  
              <button className="submit-button" onClick={handleSubmit}>
                Obtenez un devis
              </button>
            </>
          )}
        </div>
        
      </div>
        <div>
      <h2 className="info-heading">Temoignages</h2>
      <p className="intro-text">
          Nous avons déjà assisté des milliers de familles à réaliser leurs rapatriements selon leurs désirs. 
          Notre équipe comprend l’importance de chaque situation et s’efforce de fournir un service personnalisé
        </p>
      <FeedbackClients />
      </div>
      <div>
      <h2 className="info-heading">Besoin d'un rapatriementà l'étranger ?</h2>
      <div className="info-section">
        <div className="info-text">
          <p className="info-description">
          Pas de panique, tout va bien se passer ! Vous êtes entouré de spécialistes qui s'occupent de chaque aspect de votre rapatriement. Que vous soyez confronté à une urgence médicale, un besoin de retour anticipé ou d'autres raisons, notre équipe est prête à intervenir rapidement.
Ce que nous proposons : </p>
<br/>
<p className="info-description">
1- Assistance 24/7 : Notre équipe est disponible à toute heure pour répondre à vos questions et organiser votre rapatriement.</p>
<br/>
<p className="info-description">
2- Coordination médicale : Si nécessaire, nous travaillons avec des professionnels de santé pour assurer votre confort et votre sécurité pendant le transport.</p>
<br/>

<p className="info-description">
3- Gestion des formalités : Nous prenons en charge toutes les démarches administratives, y compris les documents nécessaires et les relations avec les compagnies aériennes.</p>
<br/>

<p className="info-description">
4- Transport adapté : Selon votre état de santé et vos besoins, nous vous proposons le moyen de transport le plus approprié.</p>
<br/>

<p className="info-description">
5- Soutien psychologique : Nous comprenons que ce genre de situation peut être stressant. Nos conseillers sont là pour vous soutenir moralement tout au long du processus.</p>
<br/>

          <button className="info-button">Organiser un retour au pays </button>
        </div>
        <div className="info-image">
          <img src={rafiki} alt="Monument" />
        </div>
      </div>
      </div>
      </div>
    );
  };
  
  export default DemandeDevis;