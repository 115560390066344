import React, { useState } from 'react';
import './FeedbackClients.css';
import clientimg from '../assets/image.png'
const testimonials = [
  {
    name: "Hadjila B. - Rennes",
    feedback:
      "Très bon accueil, très à l'écoute en cette période de deuil, accompagnement, services etc au top, vraiment rien à redire, conforme aux demandes de la famille... très bon suivi...merci beaucoup....",
    image: "https://scontent.ftun18-1.fna.fbcdn.net/v/t39.30808-6/450066078_1253454688953451_33486292816308942_n.jpg?stp=cp6_dst-jpg&_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=5ShR5tft_8AQ7kNvgFdqoEs&_nc_ht=scontent.ftun18-1.fna&_nc_gid=AmOG7KOnUG-ttOGOfOQGtfd&oh=00_AYAyhswieudtIwMH3YADzbvgTe6UlJpSMWhkIcodv_GTfA&oe=670B5232", // replace with the actual image path
  },
  {
    name: "Hadjila Be. - Rennes",
    feedback:
      "Très bon accueil, très à l'écoute en cette période de deuil, accompagnement, services etc au top, vraiment rien à redire, conforme aux demandes de la famille... très bon suivi...merci beaucoup....",
    image: "https://scontent.ftun18-1.fna.fbcdn.net/v/t39.30808-6/450066078_1253454688953451_33486292816308942_n.jpg?stp=cp6_dst-jpg&_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=5ShR5tft_8AQ7kNvgFdqoEs&_nc_ht=scontent.ftun18-1.fna&_nc_gid=AmOG7KOnUG-ttOGOfOQGtfd&oh=00_AYAyhswieudtIwMH3YADzbvgTe6UlJpSMWhkIcodv_GTfA&oe=670B5232", // replace with the actual image path
  },
  {
    name: "Hadjila Bx. - Rennes",
    feedback:
      "Très bon accueil, très à l'écoute en cette période de deuil, accompagnement, services etc au top, vraiment rien à redire, conforme aux demandes de la famille... très bon suivi...merci beaucoup....",
    image: "https://scontent.ftun18-1.fna.fbcdn.net/v/t39.30808-6/450066078_1253454688953451_33486292816308942_n.jpg?stp=cp6_dst-jpg&_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=5ShR5tft_8AQ7kNvgFdqoEs&_nc_ht=scontent.ftun18-1.fna&_nc_gid=AmOG7KOnUG-ttOGOfOQGtfd&oh=00_AYAyhswieudtIwMH3YADzbvgTe6UlJpSMWhkIcodv_GTfA&oe=670B5232", // replace with the actual image path
  },
  // Add more testimonials as needed
];

const FeedbackClients = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handlePrevClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };
  
    const handleNextClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };
  
    return (
      <div className="feedback-container">
        <div className="testimonial-card">
          <button className="nav-button" onClick={handlePrevClick}>‹</button>
          <div className="testimonial-content">
            <img src={testimonials[currentIndex].image} alt="Client" className="client-image" />
            <p className="feedback-text">{testimonials[currentIndex].feedback}</p>
            <p className="client-name">{testimonials[currentIndex].name}</p>
          </div>
          <button className="nav-button" onClick={handleNextClick}>›</button>
        </div>
      </div>
    );
  };
  
  export default FeedbackClients;