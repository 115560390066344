import React from 'react';
import Carousel from './components/Carousel'; 
import './Home.css'; 
import ServiceCard from './components/ServiceCard'
import FeedbackClients from './components/FeedbackClients';
import pictureMan from './assets/groupHome1.png'
import rafiki from './assets/rafiki.png'
const HomePage = () => {
  return (
    <div>
      <Carousel />
      <h2 className="info-heading">Concevez votre monument de bout en bout</h2>
      <div className="info-section">
        <div className="info-text">
          <p className="info-description">
            Chez pierre tombale France, nous comprenons que chaque individu est unique, et mérite un hommage personnalisé qui capture l’essence de sa vie. C’est pourquoi nous sommes fiers de vous offrir des monuments funéraires personnalisés, conçus avec soin pour refléter l’histoire et la personnalité uniques de vos proches.
          </p>
          <button className="info-button">Je souhaite</button>
        </div>
        <div className="info-image">
          <img src={pictureMan} alt="Monument" />
        </div>
      </div>
      <div>
      <h2 className="info-heading">Obséques</h2>
      <ServiceCard />
      </div>
      <div>
      <h2 className="info-heading">Temoignages</h2>
      <p className="intro-text">
          Nous avons déjà assisté des milliers de familles à réaliser leurs rapatriements selon leurs désirs. 
          Notre équipe comprend l’importance de chaque situation et s’efforce de fournir un service personnalisé
        </p>
      <FeedbackClients />
      </div>
      <div>
      <h2 className="info-heading">Besoin d'un rapatriement à l'étranger ?</h2>
      <div className="info-section">
        <div className="info-text">
          <p className="info-description">
          Pas de panique, tout va bien se passer ! Vous êtes entouré de spécialistes qui s'occupent de chaque aspect de votre rapatriement. Que vous soyez confronté à une urgence médicale, un besoin de retour anticipé ou d'autres raisons, notre équipe est prête à intervenir rapidement.
Ce que nous proposons : </p>
<br/>
<p className="info-description">
1- Assistance 24/7 : Notre équipe est disponible à toute heure pour répondre à vos questions et organiser votre rapatriement.</p>
<br/>
<p className="info-description">
2- Coordination médicale : Si nécessaire, nous travaillons avec des professionnels de santé pour assurer votre confort et votre sécurité pendant le transport.</p>
<br/>

<p className="info-description">
3- Gestion des formalités : Nous prenons en charge toutes les démarches administratives, y compris les documents nécessaires et les relations avec les compagnies aériennes.</p>
<br/>

<p className="info-description">
4- Transport adapté : Selon votre état de santé et vos besoins, nous vous proposons le moyen de transport le plus approprié.</p>
<br/>

<p className="info-description">
5- Soutien psychologique : Nous comprenons que ce genre de situation peut être stressant. Nos conseillers sont là pour vous soutenir moralement tout au long du processus.</p>
<br/>

          <button className="info-button">Organiser un retour au pays </button>
        </div>
        <div className="info-image">
          <img src={rafiki} alt="Monument" />
        </div>
      </div>
      </div>
      {/*<div className="new-section">
        <h2>Concevez votre monument de bout en bout</h2>
        <div className="images-container">
          <div className="image-item">
            <img src="https://monument-funeraire-france.com/wp-content/uploads/2023/09/5-34-400x400.png" alt="Image 1" />
            <button className="image-button">Je souhaite</button>
          </div>
          <div className="image-item">
            <img src="https://monument-funeraire-france.com/wp-content/uploads/2023/09/9-42-400x400.png" alt="Image 2" />
            <button className="image-button">Je souhaite</button>
          </div>
          <div className="image-item">
            <img src="https://monument-funeraire-france.com/wp-content/uploads/2023/09/7-1-16-400x400.png" alt="Image 3" />
            <button className="image-button">Je souhaite</button>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default HomePage;
