import React from 'react';
import './ProgressComponent.css';
import progressIcon from '../assets/progress_icon.png';
import progressIconNotCheck from '../assets/progress_icon_not.png';

const ProgressComponent = ({ step }) => {
  // Generate an array of step data with the state of each step
  const steps = [
    { title: 'Etape 1', description: 'Choix du produit' },
    { title: 'Etape 2', description: 'Choix du Motif' },
    { title: 'Etape 3', description: 'Renseignement' },
    { title: 'Etape 4', description: 'Paiement' },
  ];

  return (
    <div className="progress-wrapper">
      {steps.map((stepData, index) => {
        const isActive = index < step;
        return (
          <div
            key={index}
            className={`progress-step ${isActive ? 'active' : ''}`}
          >
            <div className="step-title-text">{stepData.title}</div>
            <div className="icon-wrapper">
              <img
                src={isActive ? progressIcon : progressIconNotCheck}
                alt="progress icon"
                className="icon-img"
              />
            </div>
            <div className="step-desc">{stepData.description}</div>
            {index < steps.length  && <div className="progress-line"></div>}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressComponent;
