import React, { useState, useEffect } from 'react';
import './Header.css';
import logo from '../logo.svg';
import search from '../assets/search.png';
import profile from '../assets/profile.png';
import panier from '../assets/panier.png';
import iconDrop from '../assets/dropdown_home.png'; // Import dropdown icon
import device from '../assets/logodevice.png';
import boxCalender from '../assets/bx_calendar.svg'; // Import the SVG image
import { Link } from 'react-router-dom';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [dropdownOpen, setDropdownOpen] = useState({
        obseques: false, // State for Obséques dropdown
        services: false,  // State for Services dropdown
    });

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
        setDropdownOpen({ obseques: false, services: false }); // Close all dropdowns
    };

    const toggleDropdown = (dropdown) => {
        setDropdownOpen(prevState => ({
            obseques: dropdown === 'obseques' ? !prevState.obseques : false, // Toggle Obséques dropdown
            services: dropdown === 'services' ? !prevState.services : false, // Toggle Services dropdown
        }));
    };

    return (
        <header>
            <div className="header-top">
                <div className="logo">
                    <img src={logo} alt="Logo" style={{ height: '126px', width: '176px' }} />
                </div>

                {/* Display this link only in mobile view */}
                {isMobile && (
                    <div className="mobile-rendezvous">
                        <Link to="/rendezvous" className="rendezvous-link" onClick={closeMenu}>
                            <span className="icon">
                                <img src={boxCalender} alt="Calendar Icon" style={{ height: '24px', width: '24px' }} />
                            </span>
                            Rendez-vous
                        </Link>
                    </div>
                )}

                <nav className={`nav-links ${menuOpen ? 'active' : ''}`}>
                    <button className="close-btn" onClick={closeMenu}>✕</button>
                    
                    {/* Obséques with dropdown */}
                    <div className="dropdown">
                        <Link to="/" onClick={closeMenu}>Obséques</Link>
                        <span className="icon-drop" onClick={() => toggleDropdown('obseques')}>
                            <img src={iconDrop} alt="Dropdown Icon" />
                        </span>
                        {dropdownOpen.obseques && (
                            <div className="dropdown-menu">
                                <Link to="/devis" onClick={closeMenu}>Inhumation / Enterrement</Link>
                                <Link to="/devis" onClick={closeMenu}>Crémation</Link>
                                <Link to="/devis" onClick={closeMenu}>Rapatriement à l’étranger</Link>
                            </div>
                        )}
                    </div>
                    
                    {/* Services with dropdown */}
                    <div className="dropdown">
                        <Link to="/" onClick={closeMenu}>Services</Link>
                        <span className="icon-drop" onClick={() => toggleDropdown('services')}>
                            <img src={iconDrop} alt="Dropdown Icon" />
                        </span>
                        {dropdownOpen.services && (
                            <div className="dropdown-menu">
                                <Link to="/shop" onClick={closeMenu}>Services 1</Link>
                                <Link to="/shop" onClick={closeMenu}>Services 2</Link>
                                <Link to="/shop" onClick={closeMenu}>Services 3</Link>
                            </div>
                        )}
                    </div>

                    {/* Display this link only in web view */}
                    {!isMobile && (
                        <Link to="/rendezvous" className="devis-link" onClick={closeMenu}>
                            <span className="icon">
                                <img src={device} alt="Device Icon" style={{ height: '24px', width: '24px' }} />
                            </span>
                            Devis Obséques
                        </Link>
                    )}
                    
                    {!isMobile && (
                        <Link to="/rendezvous" className="rendezvous-link" onClick={closeMenu}>
                            <span className="icon">
                                <img src={boxCalender} alt="Calendar Icon" style={{ height: '24px', width: '24px' }} />
                            </span>
                            Rendez-vous
                        </Link>
                    )}

                    {/* Add "Profile" and "Panier" inside the sidebar */}
                    <div className="sidebar-icons">
                        <span className="icon">
                            <img style={{ height: '36px', width: '36px' }} src={profile} alt="Profile" />
                        </span>
                        <span className="icon">
                            <img style={{ height: '36px', width: '36px' }} src={panier} alt="Panier" />
                        </span>
                    </div>
                </nav>

                {/* Icons for search, profile, and panier in the web version */}
                <div className="icons">
                    <Link to="/panier">
                        <span className="icon">
                            <img style={{ height: '36px', width: '36px' }} src={panier} alt="Panier" />
                        </span>
                    </Link>
                    <Link to="/signIn">
                        <span className="icon">
                            <img style={{ height: '36px', width: '36px' }} src={profile} alt="Profile" />
                        </span>
                    </Link>
                </div>

                <div className="menu-toggle" onClick={toggleMenu}>
                    ☰
                </div>
            </div>
            <div className="header-bottom">
                <div className="flag-color blue"></div>
                <div className="flag-color white"></div>
                <div className="flag-color red"></div>
            </div>

            {/* Overlay for closing the sidebar */}
            {menuOpen && <div className="overlay" onClick={closeMenu}></div>}
        </header>
    );
};

export default Header;
