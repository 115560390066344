import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './Home';
import Shop from './Shop';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from './logo.svg';  // Import your logo
import ShopDetails from './shop-details';
import ShopDetailsNext from './shop-details-next';
import SignIn from './signIn/SignIn';
import SignUp from './signUp/SignUp';
import BasketShop from './Panier';
import Renseignement from './Renseignement';
import DemandeDevis from './DemandeDevis';
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/signIn' element={< SignIn/>} />
        <Route path='/signUp' element={< SignUp/>} />
        <Route path='/panier' element={< BasketShop/>} />
        <Route path='/Renseignement' element={<Renseignement/>}/>
        <Route path="/" element={<HomePage />} />
        <Route path="/devis" element={<DemandeDevis />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop-details/:id" element={<ShopDetails />} />
        <Route path="/shop-details-next/:id" element={<ShopDetailsNext />} />
      </Routes>
      <Footer />
      <FloatingWhatsApp 
        phoneNumber='330185120288' 
        accountName='Obseque' 
        avatar={logo}
        statusMessage="Répond généralement dans l'heure"
        chatMessage={'Bonjour! 🤝\nComment pouvons-nous vous aider ?'}
      />
    </Router>
  );
}

export default App;
