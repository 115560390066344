import React, { useState,useEffect  } from 'react';
import './shop-details-next.css'; // Import the CSS file for styles
import { useParams } from 'react-router-dom';
import panier from './assets/panier.png'
import ProgressComponent from './components/ProgressComponent';
import { Link } from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css'; // Import notification CSS

 // Function to save the selected motif to localStorage
 function saveSelectedMotifToStorage(motif) {
    try {
        // Check if the motif is valid
        if (!motif || !motif.image || !motif.description || !motif.price) {
            throw new Error('Motif is incomplete');
        }

        // Retrieve existing cart data from localStorage, if any
        let cartData = JSON.parse(localStorage.getItem('cart')) || {};

        // Initialize Motif array if it doesn't exist
        if (!cartData['Motifs']) {
            cartData['Motifs'] = [];
        }

        // Add the selected motif data to the Motifs array
        cartData['Motifs'].push({
            image: motif.image,
            description: motif.description,
            price: motif.price
        });

        // Save the updated cart object to localStorage
        localStorage.setItem('cart', JSON.stringify(cartData));

        // Success notification
        NotificationManager.success('Motif added to cart successfully!', 'Success');
    } catch (error) {
        // Failure notification
        NotificationManager.error(error.message, 'Failed to Add Motif');
    }
}

const ShopDetailsNext = () => {
    const { id } = useParams(); // Get the product ID from the URL

    
    const [motifs, setMotifs] = useState([]);
    useEffect(() => {
        const fetchMotifs = async () => {
            try {
                const response = await fetch('http://localhost:4000/motif/all');
                const data = await response.json();
                setMotifs(data); // Update the motifs state with the fetched data
            } catch (error) {
                console.error('Error fetching motifs:', error);
            }
        };

        fetchMotifs(); // Call the fetch function when the component mounts
    }, []); // Empty dependency array ensures this runs only once
  // Initialize state before any conditional logic
  // Find the product by ID
  const [selectedMotif, setSelectedMotif] = useState(null);
 // Initialize state before any conditional logic
 const [quantity, setQuantity] = useState(1);
 const [currentIndex, setCurrentIndex] = useState(0);
 // Find the product by ID

 // Function to show the snackbar for 3 seconds
 const handleMotifClick = (motif) => {
    setSelectedMotif(motif);
};

const handleAddToCartClick = () => {
    saveSelectedMotifToStorage(selectedMotif);
};

// Handler for the "Suivant" link
const handleNextClick = () => {
    saveSelectedMotifToStorage(selectedMotif);
};
 const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  return (
    <div className="container-all">
              <ProgressComponent step={2} />
        <div className="container">
        <div className="product-details">
            <div className="left-column">
            <h2 className="product-name">Motif</h2>
    <div style={{ border: '1px solid gray', padding: 20 }}>
        
        <div className="motifs-grid">
        {motifs.length > 0 ? (
                                    motifs.map((motif, index) => (
                                        <div
                                            key={motif._id}
                                            className="motif-item"
                                            onClick={() => handleMotifClick(motif)}
                                        >
                                            <img src={motif.image} alt={motif.description} />
                                            <div className="motif-info">
                                                <span className="motif-name">{motif.description}</span>
                                                <span className="motif-price">{motif.price} €</span>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>Loading motifs...</p>
                                )}
        </div>
    </div>
            </div>
            <div className="right-column">
            <p style={{color : "#0074A6", fontSize: "18px"}}>Etape 2</p>

                <div style={{ border: '1px solid gray', padding:20 }}>
                <h1>{selectedMotif ? selectedMotif.description : ""}</h1>
                <h2>{selectedMotif ? selectedMotif.price+" €" : ""}</h2>
                <p>
                Potentille: Simplicité Élégante dans l'Esthétique Asiatique en 11 Couleurs
              </p>
              <p>
                Le monument funéraire Potentille, incarnant la simplicité élégante de
                l'esthétique asiatique, se dresse comme un hommage épuré, soulignant
                la beauté de la vie à travers une palette de 11 couleurs distinctes.
              </p>
                <div className="price-details">
                    <div className="price-item">
                        <span className="price-item-label">Total du produit</span>
                        <span className="price-item-value">6490,00 €</span>
                    </div>
                    <div className="price-item">
                        <span className="price-item-label">Total des options</span>
                        <span className="price-item-value">0,00 €</span>
                    </div>
                    <div className="price-item">
                        <span className="price-item-label">Total</span>
                        <span className="price-item-value">6490,00 €</span>
                    </div>
                    <div className="quantity-selector">
    <button
        className="quantity-button"
        onClick={() => setQuantity(Math.max(1, quantity - 1))}
        disabled={quantity === 1}
    >
        -
    </button>
    <input
        type="number"
        className="quantity-input"
        value={quantity}
        onChange={handleQuantityChange}
        min="1"
    />
    <button
        className="quantity-button"
        onClick={() => setQuantity(quantity + 1)}
        disabled={quantity === 10}
    >
        +
    </button>
</div>

                </div>
                <div className="buttons">
    <button className="add-to-cart" onClick={handleAddToCartClick} >
        <img src={panier} alt="Basket Icon" className="icon" />
        Ajouter au panier
    </button>
    <Link
                                    to={ `/Renseignement`
                                    }
                                    className="next" onClick={handleNextClick}
                                >
                                    Suivant
                                </Link>
                                <NotificationContainer />

                                </div>

                </div>
                
   
</div>

         
        </div>
        
    </div>
           <div className="product-full-description">
                <p><strong>Potentille : Simplicité Élégante dans l’Esthétique Asiatique en 11 Couleurs</strong></p>
                <p>Le monument funéraire Potentille, incarnant la simplicité élégante de l’esthétique asiatique, se dresse comme un hommage épuré, soulignant la beauté de la vie à travers une palette de 11 couleurs distinctes.</p>

                <h3>Caractéristiques du Monument Potentille :</h3>
                <ul>
                    <li>Soubassement</li>
                    <li>Stèle en forme de portail Chinoise</li>
                    <li>Semelle</li>
                    <li>Socle</li>
                    <li>Simplicité Élégante : Potentille célèbre la beauté dans la simplicité, rappelant la pureté de l’âme.</li>
                </ul>

                <h3>11 Types de Granit Disponibles :</h3>
                <ul>
                    <li>Gris – Équilibre et sobriété intemporelle.</li>
                    <li>Noir – Profondeur et mystère éternels.</li>
                    <li>Rouge – Passion et énergie ardente.</li>
                    <li>Vert – Renouveau et connexion à la nature.</li>
                    <li>Viscount White – Pureté immaculée en blanc.</li>
                    <li>Leopard Rose – Motifs uniques mêlant rose et noir.</li>
                    <li>Leopard Beige – Chaleur et douceur avec des tons beige.</li>
                    <li>Himalaya – Évocation majestueuse des sommets himalayens.</li>
                    <li>Labrador Noir – Profondeur classique en noir.</li>
                    <li>Labrador Blue HQ – Touche distinctive de bleu élégant.</li>
                    <li>Rosso Santiago – Symbolisant la passion, avec une base verte et des taches subtiles en beige.</li>
                </ul>

                <h3>Personnalisation Culturelle :</h3>
                <p>Inspirations Asiatiques : Chaque Potentille peut être personnalisé avec des éléments inspirés de la tradition asiatique, ajoutant une signification culturelle profonde.</p>

                <h3>Utilisations Recommandées :</h3>
                <ul>
                    <li>Monuments Funéraires : Potentille offre une option d’une simplicité raffinée pour les pierres tombales, columbariums et autres monuments funéraires.</li>
                    <li>Stèles Asiatiques : En tant que stèle asiatique, Potentille devient une pièce maîtresse sobre dans un jardin du souvenir ou un mémorial.</li>
                    <li>Options de Marbrerie : Intégrez la simplicité élégante de Potentille dans des éléments de marbrerie pour créer des pièces artistiques liées à la tradition asiatique.</li>
                </ul>

                <p>Que Potentille soit le symbole épuré qui honore la mémoire de vos proches, unifiant la richesse des couleurs à la célébration de leurs vies uniques.</p>
            </div>
    </div>
    
);
};
export default ShopDetailsNext;