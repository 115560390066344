import React from 'react';
import './ServiceCard.css'; // CSS for the styling
import coffinIcon from '../assets/coffin_4582580 1.png';
import illumination from '../assets/illumination_14124261 1.png';
import airplane from '../assets/airplane_479822 1.png';
const ServiceCard = ({ icon, title, description }) => {
  return (
    <div className="card">
      <div className="icon-container">
        <img src={icon} alt={title} className="service-icon" />
      </div>
      <h2 className="card-title">{title}</h2>
      <p className="card-description">{description}</p>
      <button className="cta-button">
        <i className="fas fa-clipboard"></i> Je demande un devis
      </button>
    </div>
  );
};

const Services = () => {
  return (
    <div className="services-container">
      <ServiceCard 
         icon={coffinIcon}
        title="Inhumation \ Enterrement" 
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."
      />
      <ServiceCard 
        icon={illumination}
        title="Crémation" 
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."
      />
      <ServiceCard 
        icon={airplane}
        title="Rapatriement à l'étranger" 
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."
      />
    </div>
  );
};

export default Services;
