import React, { useEffect, useState } from 'react';
import './Renseignement.css';
import trashIcon from './assets/trash.png'; // Import your icon
import ProgressComponent from './components/ProgressComponent';

const Renseignement = () => {
    const [cartData, setCartData] = useState(null);

    // Fetch data from localStorage on component mount
    useEffect(() => {
        const storedCartData = JSON.parse(localStorage.getItem('cart'));
        if (storedCartData) {
            setCartData(storedCartData);
        }
    }, []);

    return (
        <div className="container-Renseignement">
            <ProgressComponent step={3} />
            <br />
            <h1 className="title">Renseignement sur le cimetière</h1>

            {/* Cemetery Info Section */}
            <div className="form-container">
                <div className="field-group">
                    <label htmlFor="cemeteryName" className="label">Nom du cimetière:</label>
                    <input type="text" id="cemeteryName" className="input-field" />
                </div>
                <div className="field-group">
                    <label htmlFor="city" className="label">Ville:</label>
                    <input type="text" id="city" className="input-field" />
                </div>
            </div>

            {/* Display Product if available */}
            {cartData?.produit && (
                <div className="product-container-Renseignement">
                    <div className="image-container-Renseignement">
                        <img
                            src={cartData.produit.image} // Product image from localStorage
                            alt="Product"
                            className="image-product-Renseignement"
                        />
                    </div>
                    <div className="product-details-Renseignement">
                        <div className="header-Renseignement">
                            <span className="header-text">Produit</span>
                            <img src={trashIcon} alt="Trash Icon" className="header-icon" />
                        </div>
                        <div className="detail-Renseignement">
                            Total du produit: <span>{cartData.produit.quantity}</span>
                        </div>
                        <div className="detail-Renseignement">
                            Total des options: <span>{cartData.produit.price} €</span>
                        </div>
                        <div className="total-Renseignement">
                            Total: <span>{cartData.produit.price} €</span>
                        </div>
                    </div>
                </div>
            )}

            {/* Display Motifs if available */}
            {cartData?.Motifs && cartData.Motifs.map((motif, index) => (
                <div className="product-container-Renseignement" key={index}>
                    <div className="image-container-Renseignement">
                        <img
                            src={motif.image} // Motif image from localStorage
                            alt="Motif"
                            className="image-product-Renseignement"
                        />
                    </div>
                    <div className="product-details-Renseignement">
                        <div className="header-Renseignement">
                            <span className="header-text">Motif</span>
                            <img src={trashIcon} alt="Trash Icon" className="header-icon" />
                        </div>
                        <div className="detail-Renseignement">
                            Motif du produit: <span>{motif.description}</span>
                        </div>
                        <div className="detail-Renseignement">
                            Motif des options: <span>{motif.price} €</span>
                        </div>
                        <div className="total-Renseignement">
                            Total: <span>{motif.price} €</span>
                        </div>
                    </div>
                </div>
            ))}

            {/* Total Payable */}
            <div className="separator-line"></div>
            <div className="total-payable-container">
                <div className="total-text">Total À payer</div>
                <div className="total-amount">
                    {/* Calculate and display the total */}
                    {cartData ? 
                        (Number(cartData?.produit?.price || 0) * (cartData.produit?.quantity || 1) + 
                        cartData.Motifs.reduce((acc, motif) => acc + motif.price, 0)) + ' €' 
                        : '0 €'}
                </div>
            </div>

            <div className="separator-line"></div>
            <div className="total-payable-container">
                <button className='button-pay-Renseignement'>Payer</button>
            </div>
        </div>
    );
};

export default Renseignement;
